import { Button } from '@mui/material'
import { capitalize } from 'lodash'
import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router'
import Swal from 'sweetalert2'
import { Delete, Post, Put } from '../api/actions'
import url from '../api/url'
import useRedux from '../redux/hooks/useRedux'
import { fetchUsersFull as fetchUsers } from '../redux/mainActions'

const EditUserScreen = ({ setModalShowing, editUser = null }) => {
  const dispatch = useDispatch()
  const [notResettingPassword, setNotResettingPassword] = useState(true)
  const [userDetails, setUserDetails] = useState({
    email: '',
    first_name: '',
    last_name: '',
    one_time_password: '',
    confirmed_one_time_password: '',
    admin: '0',
  })

  const handleChange = (e, t) => {
    setUserDetails({ ...userDetails, [t]: e.target.value })
  }

  useEffect(() => {
    if (editUser) {
      setUserDetails({ ...editUser })
    }
  }, [])

  return (
    <div
      style={{
        backgroundColor: 'rgba(0,0,0,0.5)',
        width: '100vw',
        height: '100vh',
        position: 'absolute',
        top: 0,
        paddingTop: '50px',
        left: 0,
        zIndex: 100,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
      onClick={(e) => {
        e.stopPropagation()
        e.preventDefault()
        setModalShowing(false)
      }}
    >
      <div className="card" style={{ width: '60vw', borderRadius: '10px' }}>
        <div className="card-body">
          <form
            className="form-horizontal form-material mx-2"
            onClick={(e) => {
              e.stopPropagation()
              e.preventDefault()
            }}
          >
            <div className="form-group">
              <label className="col-md-12 mb-0">Given Name:</label>
              <div className="col-md-12">
                <input
                  type="text"
                  className="form-control ps-0 form-control-line"
                  id="first_name_input"
                  onChange={(e) => {
                    handleChange(e, 'first_name')
                  }}
                  value={userDetails.first_name}
                />
              </div>
            </div>
            <div className="form-group">
              <label className="col-md-12 mb-0">Family Name:</label>
              <div className="col-md-12">
                <input
                  type="text"
                  id="last_name_input"
                  className="form-control ps-0 form-control-line"
                  onChange={(e) => {
                    handleChange(e, 'last_name')
                  }}
                  value={userDetails.last_name}
                />
              </div>
            </div>
            <div className="form-group">
              <label for="example-email" className="col-md-12">
                Email:
              </label>
              <div className="col-md-12">
                <input
                  type="email"
                  id="email_input"
                  className="form-control ps-0 form-control-line"
                  name="example-email"
                  disabled={editUser}
                  onChange={(e) => {
                    handleChange(e, 'email')
                  }}
                  value={userDetails.email}
                />
              </div>
            </div>
            <div className="form-group">
              <label className="col-md-12 mb-0">
                Make user admin:{' '}
                <input
                  type="checkbox"
                  id="admin_input"
                  key={Math.random()}
                  //   className="form-control ps-0 form-control-line"
                  onChange={(e) => {
                    let _userDetails = { ...userDetails }
                    _userDetails.admin = e.target.checked ? '1' : '0'
                    setUserDetails({ ..._userDetails })
                  }}
                  checked={userDetails.admin === '1'}
                />
              </label>
            </div>
            {!editUser || !notResettingPassword ? (
              <>
                <div className="form-group">
                  <label className="col-md-12 mb-0">
                    First time password:
                    <span style={{ opacity: '0.4', marginLeft: '10px' }}>
                      (User will be asked to change this on first login)
                    </span>
                  </label>
                  <div className="col-md-12">
                    <input
                      type="password"
                      id="password_input"
                      onChange={(e) => {
                        handleChange(e, 'one_time_password')
                      }}
                      value={userDetails.one_time_password}
                      className="form-control ps-0 form-control-line"
                    />
                  </div>
                </div>
                <div className="form-group">
                  <label className="col-md-12 mb-0">
                    Confirm first time password:
                  </label>
                  <div className="col-md-12">
                    <input
                      type="password"
                      id="confirm_password_input"
                      className="form-control ps-0 form-control-line"
                      onChange={(e) => {
                        handleChange(e, 'confirmed_one_time_password')
                      }}
                      value={userDetails.confirmed_one_time_password}
                    />
                  </div>
                </div>
              </>
            ) : (
              <>
                <div className="form-group">
                  <label
                    className="col-md-12 mb-0"
                    style={{ textDecoration: 'underline', cursor: 'pointer' }}
                    onClick={() => {
                      setNotResettingPassword(false)
                    }}
                  >
                    Reset user's password
                  </label>
                </div>
                <div className="form-group">
                  <label
                    className="col-md-12 mb-0"
                    style={{ textDecoration: 'underline', cursor: 'pointer' }}
                    onClick={() => {
                      Swal.fire({
                        icon: 'warning',
                        title: 'Are you sure you want to delete this user?',
                        text: 'This action cannot be undone',
                        showCancelButton: true,
                      }).then((response) => {
                        if (response.dismiss) {
                          return
                        } else {
                          Delete(`/user?email=${userDetails.email}`)
                            .then(({ data }) => {
                              if (data.result === 'Success') {
                                dispatch(fetchUsers())
                                Swal.fire({
                                  icon: 'success',
                                  title: 'Successfully deleted user',
                                })
                              } else {
                                Swal.fire({
                                  icon: 'error',
                                  title: "Couldn't delete user: " + data.text,
                                })
                              }
                              setModalShowing(false)
                            })
                            .catch((err) => {
                              Swal.fire({
                                icon: 'error',
                                title: "Couldn't delete user: " + err,
                              })
                              setModalShowing(false)
                            })
                        }
                      })
                    }}
                  >
                    Delete user
                  </label>
                </div>
              </>
            )}

            <div className="form-group">
              <div className="col-sm-12">
                <button
                  className="btn btn-danger mx-auto mx-md-0 text-white"
                  style={{ marginLeft: '50px' }}
                  onClick={() => {
                    setModalShowing(false)
                  }}
                >
                  Cancel
                </button>
                <div style={{ marginLeft: '10px', display: 'inline-block' }}>
                  <button
                    className="btn btn-success mx-auto mx-md-0 text-white"
                    onClick={() => {
                      const unfilledFields = Object.entries(userDetails)
                        .map(([key, value]) => ({ key, value }))
                        .filter((x) => typeof x.value === 'string')
                        .filter((x) => !x.value.length)
                      if (unfilledFields.length) {
                        Swal.fire({
                          icon: 'error',
                          title: 'All fields are required',
                          text:
                            'Please ensure you enter ' +
                            unfilledFields
                              .map((x) =>
                                capitalize(x.key.replaceAll('_', ' ')),
                              )
                              .join(', '),
                        })
                        return
                      }
                      if (
                        userDetails.one_time_password !==
                        userDetails.confirmed_one_time_password
                      ) {
                        Swal.fire({
                          icon: 'error',
                          title: "Passwords don't match",
                        })
                        return
                      }
                      if (editUser) {
                        Put('/user', userDetails)
                          .then(({ data }) => {
                            if (data.result === 'Success') {
                              dispatch(fetchUsers())
                              Swal.fire({
                                icon: 'success',
                                title: 'Successfully edited user',
                              })
                            } else {
                              Swal.fire({
                                icon: 'error',
                                title: "Couldn't edit user: " + data.text,
                              })
                            }
                            setModalShowing(false)
                          })
                          .catch((err) => {
                            Swal.fire({
                              icon: 'error',
                              title: "Couldn't edit user: " + err,
                            })
                            setModalShowing(false)
                          })
                      } else {
                        Post('/user', userDetails)
                          .then(({ data }) => {
                            if (data.result === 'Success') {
                              dispatch(fetchUsers())
                              Swal.fire({
                                icon: 'success',
                                title: 'Successfully created user',
                              })
                            } else {
                              Swal.fire({
                                icon: 'error',
                                title: "Couldn't create user: " + data.text,
                              })
                            }
                            setModalShowing(false)
                          })
                          .catch((err) => {
                            Swal.fire({
                              icon: 'error',
                              title: "Couldn't create user: " + err,
                            })
                            setModalShowing(false)
                          })
                      }
                    }}
                  >
                    {editUser ? 'Save Changes' : 'Create User'}
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  )
}

export const UserManagement = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [modalShowing, setModalShowing] = useState(false)
  const [editingUser, setEditingUser] = useState(false)
  const { users } = useRedux()

  useEffect(() => {
    dispatch(fetchUsers())
  }, [])

  return (
    <>
      <div className="page-wrapper">
        <div className="page-breadcrumb">
          <div className="row align-items-center">
            <div className="col-md-6 col-8 align-self-center">
              <h3 className="page-title mb-0 p-0">User Management</h3>
              <div className="d-flex align-items-center">
                <nav aria-label="breadcrumb">
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                      <a
                        href="#"
                        onClick={() => {
                          navigate('/')
                        }}
                      >
                        Home
                      </a>
                    </li>
                    <li className="breadcrumb-item active" aria-current="page">
                      User Management
                    </li>
                  </ol>
                </nav>
              </div>
            </div>
            <div style={{ display: 'flex', justifyContent: 'right' }}>
              <Button
                variant="contained"
                onClick={() => {
                  setModalShowing(true)
                  setEditingUser(false)
                }}
              >
                Add user
              </Button>
            </div>
          </div>
        </div>
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-12">
              <div className="card" style={{ marginBottom: '0px' }}>
                <div className="card-body">
                  <div className="table-responsive">
                    <table className="table stylish-table no-wrap">
                      <thead>
                        <tr>
                          <th></th>
                          <th>Name</th>
                          <th>Email</th>
                          <th>Admin</th>
                        </tr>
                      </thead>
                      <tbody>
                        {users.map(
                          ({ email, first_name, last_name, color, admin }) => (
                            <tr
                              style={{ cursor: 'pointer' }}
                              onClick={() => {
                                setModalShowing(true)
                                setEditingUser({
                                  email,
                                  first_name,
                                  last_name,
                                  admin,
                                })
                              }}
                            >
                              <td style={{ width: '50px' }}>
                                <span
                                  className="round"
                                  style={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    backgroundColor: color,
                                  }}
                                >
                                  {capitalize(first_name.slice(0, 1))}
                                </span>
                              </td>
                              <td className="align-middle">
                                {first_name} {last_name}
                              </td>
                              <td className="align-middle">{email}</td>
                              <td style={{ width: '50px' }}>
                                {admin === '1' ? (
                                  <span
                                    className="round"
                                    style={{
                                      display: 'flex',
                                      justifyContent: 'center',
                                      alignItems: 'center',
                                      backgroundColor: 'rgba(0,0,0,0)',
                                      color: '#20c997',
                                    }}
                                  >
                                    <i
                                      className={`me-3 mdi mdi-checkbox-marked-circle fa-fw`}
                                      aria-hidden="true"
                                      style={{
                                        fontSize: '1.3em',
                                        marginLeft: '15px',
                                      }}
                                    ></i>
                                  </span>
                                ) : (
                                  <span
                                    className="round"
                                    style={{
                                      display: 'flex',
                                      justifyContent: 'center',
                                      alignItems: 'center',
                                      backgroundColor: 'rgba(0,0,0,0)',
                                      color: '#f62d51',
                                    }}
                                  >
                                    <i
                                      className={`me-3 mdi mdi-close-circle fa-fw`}
                                      aria-hidden="true"
                                      style={{
                                        fontSize: '1.3em',
                                        marginLeft: '15px',
                                      }}
                                    ></i>
                                  </span>
                                )}
                              </td>
                            </tr>
                          ),
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {modalShowing ? (
        <EditUserScreen
          setModalShowing={setModalShowing}
          setEditingUser={setEditingUser}
          editUser={editingUser}
        />
      ) : null}
    </>
  )
}
export default UserManagement
