import '../src/scss/style.scss'

import { Provider } from 'react-redux'
import store from './redux/store'
import AppWrapper from './AppWrapper'

function App() {
  return (
    <Provider store={store}>
      <div className="App">
        <AppWrapper />
      </div>
    </Provider>
  )
}

export default App
