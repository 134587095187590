import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom'
import ProfilePage from './Components/ProfilePage'
import MyFiles from './Components/MyFiles'
import TopBar from './Components/TopBar'
import { SideBar } from './Components/SideBar'
import useRedux from './redux/hooks/useRedux'
import Login from './Components/Login'
import { Admin } from './Components/Admin'
import UserManagement from './Components/UserManagement'
import SharePage from './Components/SharePage'
import Versions from './Components/Versions'

const AppWrapper = () => {
  const { loggedIn, admin } = useRedux()

  return (
    <Router>
      {/* <div class="preloader">
    <div class="lds-ripple">
      <div class="lds-pos"></div>
      <div class="lds-pos"></div>
    </div>
  </div> */}
      <div
        id="main-wrapper"
        data-layout="vertical"
        data-navbarbg="skin6"
        data-sidebartype="full"
        data-sidebar-position="absolute"
        data-header-position="absolute"
        data-boxed-layout="full"
      >
        {loggedIn ? (
          <>
            <TopBar />
            <SideBar />
          </>
        ) : null}
        <Routes>
          {loggedIn ? (
            <>
              {/* <Route path="/profile" element={<ProfilePage />} /> */}
              {admin ? (
                <>
                  <Route path="/admin" element={<Admin />} />
                  <Route path="/users" element={<UserManagement />} />
                </>
              ) : null}
              <Route path="/share/:fileName" element={<SharePage />} />
              <Route path="/versions/:fileName" element={<Versions />} />
              <Route path="/" element={<MyFiles />} />
            </>
          ) : (
            <Route path="/*" element={<Login />} />
          )}
          {/* <Route path="/profile" element={<ProfilePage />} />

            <Route path="/my-files" element={<MyFiles />} />
            <Route path="/" element={<Login />} /> */}
        </Routes>
      </div>
    </Router>
  )
}
export default AppWrapper
