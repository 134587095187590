import { capitalize } from 'lodash'
import { useState } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router'
import logoText from '../assets/images/logo-text-new.png'
import userIcon from '../assets/images/users/1.jpg'
import useRedux from '../redux/hooks/useRedux'
import { setSearchText, setSearcText } from '../redux/mainActions'

const TopBar = () => {
  const navigate = useNavigate()
  const { username, searchText } = useRedux()
  const dispatch = useDispatch()
  return (
    <header className="topbar" data-navbarbg="skin6">
      <nav className="navbar top-navbar navbar-expand-md navbar-dark">
        <div className="navbar-header" data-logobg="skin6">
          <a
            className="navbar-brand"
            style={{ cursor: 'pointer' }}
            onClick={() => {
              navigate('/')
            }}
          >
            {/* <b className="logo-icon">
              <img src={logo} alt="homepage" className="dark-logo" />
            </b> */}
            <div
              style={{
                width: '200px',
                height: '100px',
                position: 'relative',
              }}
            >
              <img
                src={logoText}
                alt="homepage"
                className="dark-logo"
                style={{
                  width: '100%',
                  height: '100%',
                  top: '-10px',
                  position: 'absolute',
                }}
              />
            </div>
          </a>
          <a className="nav-toggler waves-effect waves-light text-dark d-block d-md-none">
            <i className="ti-menu ti-close"></i>
          </a>
        </div>
        <div
          className="navbar-collapse collapse"
          id="navbarSupportedContent"
          data-navbarbg="skin5"
        >
          <ul
            className="navbar-nav me-auto mt-md-0 "
            style={{ position: 'relative', top: '-15px' }}
          >
            <li className="nav-item hidden-sm-down">
              <form className="app-search ps-3">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Search for..."
                  value={searchText}
                  onChange={(e) => {
                    dispatch(setSearchText(e.target.value))
                  }}
                />{' '}
                <a className="srh-btn">
                  <i className="ti-search"></i>
                </a>
              </form>
            </li>
          </ul>

          <ul
            className="navbar-nav"
            style={{ position: 'relative', top: '-15px' }}
          >
            <li className="nav-item dropdown">
              <a
                className="nav-link dropdown-toggle waves-effect waves-dark"
                id="navbarDropdown"
                role="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                {/* <img src={userIcon} alt="user" className="profile-pic me-2" /> */}
                {capitalize(username)}
              </a>
              <ul
                className="dropdown-menu show"
                aria-labelledby="navbarDropdown"
              ></ul>
            </li>
          </ul>
        </div>
      </nav>
    </header>
  )
}

export default TopBar
