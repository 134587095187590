import Table from './FilesTable'
import dayjs from 'dayjs'
import { Button } from '@mui/material'
import { useEffect, useRef, useState } from 'react'
import url from '../api/url'
import { getSid } from '../api/sid'
import { useDispatch } from 'react-redux'
import { fetchFiles } from '../redux/mainActions'
import useRedux from '../redux/hooks/useRedux'
import FilesTable from './FilesTable'
import Swal from 'sweetalert2'
import ContextMenu from './ContextMenu'
import { Get } from '../api/actions'
import { useNavigate } from 'react-router'

// const files = [
//   {
//     name: 'MyFile',
//     dateAccessed: dayjs(Date.now()).format('HH:MM DD-MM-YYYY'),
//     dateCreated: dayjs(Date.now()).format('HH:MM DD-MM-YYYY'),
//     owner: 'Me',
//   },
// ]

const MyFiles = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const [modalShowing, setModalShowing] = useState(false)

  let { files, searchText, loggedIn } = useRedux()

  // filter files to only display the latest versions
  files = files.filter((file) => {
    const highestVersion = files
      .filter((x) => x.name === file.name)
      .reduce((p, c) => {
        return parseInt(c.version_no) > parseInt(p.version_no) ? c : p
      })
    return file.version_no == highestVersion.version_no
  })

  if (searchText.length > 2) {
    files = files.filter((x) => x.name.indexOf(searchText) > -1)
  }
  const hiddenFileInput = useRef(null)

  useEffect(() => {
    if (loggedIn) {
      dispatch(fetchFiles())
    }
  }, [])

  const [currentTime, setCurrentTime] = useState(-1)
  useEffect(() => {
    Get(`/time`).then(({ data }) => {
      setCurrentTime(parseInt(data))
    })
  }, [])

  return (
    <>
      <div className="page-wrapper" style={{ overflow: 'hidden' }}>
        <div className="page-breadcrumb">
          <div className="row align-items-center">
            <div className="col-md-6 col-8 align-self-center">
              <h3 className="page-title mb-0 p-0">My Files</h3>
              Current time:{' '}
              {currentTime
                ? dayjs.unix(currentTime).format('HH:mm DD-MM-YY')
                : null}
              <div className="d-flex align-items-center">
                <nav aria-label="breadcrumb">
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                      <a
                        href="#"
                        onClick={() => {
                          navigate('/')
                        }}
                      >
                        Home
                      </a>
                    </li>
                    <li className="breadcrumb-item active" aria-current="page">
                      My Files
                    </li>
                  </ol>
                </nav>
              </div>
            </div>
            <div style={{ display: 'flex', justifyContent: 'right' }}>
              <Button
                variant="contained"
                onClick={(e) => {
                  hiddenFileInput.current.click()
                }}
              >
                Upload file
              </Button>
              <input
                type="file"
                style={{ display: 'none' }}
                ref={hiddenFileInput}
                onChange={(e) => {
                  const fileUploaded = e.target.files[0]
                  const formData = new FormData()

                  formData.append('file', fileUploaded)
                  fetch(url + '/file', {
                    method: 'POST',
                    headers: { sid: getSid() },
                    body: formData,
                  })
                    .then((response) => response.json())
                    .then((result) => {
                      console.log('Success:', result)
                      if (result.status) {
                        dispatch(fetchFiles())
                        Swal.fire({
                          icon: 'success',
                          title: 'Successfully uploaded file',
                        })
                      } else {
                        if (!fileUploaded) return
                        Swal.fire({
                          icon: 'error',
                          title: "Couldn't upload file: " + result.message,
                        })
                      }
                    })
                    .catch((error) => {
                      console.error('Error:', error)
                      Swal.fire({
                        icon: 'error',
                        title: 'Error uploading file: ' + error,
                      })
                    })
                }}
              />
            </div>
          </div>
        </div>
        <FilesTable
          data={files}
          tableTitle="My Files"
          showModal={(name, originalName) => {
            setModalShowing(name, originalName)
          }}
        />
      </div>
      {modalShowing ? (
        <div
          style={{
            backgroundColor: 'rgba(0,0,0,0.5)',
            width: '100vw',
            height: '100vh',
            position: 'fixed',
            top: 0,
            left: 0,
            zIndex: 100,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
          onClick={(e) => {
            e.stopPropagation()
            e.preventDefault()
            setModalShowing(false)
          }}
        >
          <ContextMenu
            selectedFile={modalShowing}
            close={() => {
              setModalShowing(false)
            }}
          />
        </div>
      ) : null}
    </>
  )
}

export default MyFiles
