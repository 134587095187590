import { Button } from '@mui/material'
import { capitalize } from 'lodash'
import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate, useParams } from 'react-router'
import Swal from 'sweetalert2'
import { Delete, Post } from '../api/actions'
import useRedux from '../redux/hooks/useRedux'
import { fetchFiles, fetchUsers } from '../redux/mainActions'

export const SharePage = () => {
  const { users: u, files, username } = useRedux()
  const navigate = useNavigate()
  let users = u.filter((x) => x.email !== username)
  const dispatch = useDispatch()
  const { fileName } = useParams()

  const file = files.find((x) => x.name == fileName)

  useEffect(() => {
    dispatch(fetchUsers())
  }, [])

  return (
    <>
      <div className="page-wrapper">
        <div className="page-breadcrumb">
          <div className="row align-items-center">
            <div className="col-md-6 col-8 align-self-center">
              <h3 className="page-title mb-0 p-0">
                Share permissions for file: {file['name']}
              </h3>
              <div className="d-flex align-items-center">
                <nav aria-label="breadcrumb">
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                      <a
                        href="#"
                        onClick={() => {
                          navigate('/')
                        }}
                      >
                        Home
                      </a>
                    </li>
                    <li className="breadcrumb-item active" aria-current="page">
                      Share file
                    </li>
                  </ol>
                </nav>
              </div>
            </div>
            <div style={{ display: 'flex', justifyContent: 'right' }}>
              {users.filter((u) => {
                const shared_with = file.shared_with
                  .split(',')
                  .find((x) => x === u._id.toString())
                return shared_with !== undefined
              }).length === users.length ? (
                <Button
                  variant="outlined"
                  onClick={() => {
                    Swal.fire({
                      icon: 'warning',
                      title:
                        'Are you sure you want to stop sharing this file with everyone?',
                      showCancelButton: true,
                    }).then((response) => {
                      if (response.dismiss) {
                        return
                      } else {
                        Delete('/file/share?f=' + file.name, {
                          filename: file.name,
                        })
                          .then(({ data }) => {
                            if (data.result === 'Success') {
                              dispatch(fetchFiles())
                              Swal.fire({
                                icon: 'success',
                                title: 'Stopped sharing file successfully',
                              })
                            } else {
                              Swal.fire({
                                icon: 'error',
                                title:
                                  'Something went wrong when stopping sharing the file',
                                text: data.text,
                              })
                            }
                          })
                          .catch((err) => {
                            Swal.fire({
                              icon: 'error',
                              title:
                                'Something went wrong when stopping sharing the file',
                              text: err.response.data.text,
                            })
                          })
                      }
                    })
                  }}
                >
                  Unshare file with everyone
                </Button>
              ) : (
                <Button
                  variant="outlined"
                  onClick={() => {
                    Swal.fire({
                      icon: 'warning',
                      title:
                        'Are you sure you want to share this file with everyone?',
                      showCancelButton: true,
                    }).then((response) => {
                      if (response.dismiss) {
                        return
                      } else {
                        Post('/file/share', {
                          filename: file.name,
                        })
                          .then(({ data }) => {
                            if (data.result === 'Success') {
                              dispatch(fetchFiles())
                              Swal.fire({
                                icon: 'success',
                                title: 'Shared file successfully',
                              })
                            } else {
                              Swal.fire({
                                icon: 'error',
                                title:
                                  'Something went wrong when sharing the file',
                                text: data.text,
                              })
                            }
                          })
                          .catch((err) => {
                            Swal.fire({
                              icon: 'error',
                              title:
                                'Something went wrong when sharing the file',
                              text: err.response.data.text,
                            })
                          })
                      }
                    })
                  }}
                >
                  Share file with everyone
                </Button>
              )}
            </div>
          </div>
        </div>
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-12">
              <div className="card" style={{ marginBottom: '0px' }}>
                <div className="card-body">
                  <div className="table-responsive">
                    <table className="table stylish-table no-wrap">
                      <thead>
                        <tr>
                          <th></th>
                          <th>Name</th>
                          <th>Email</th>
                          <th>Shared with</th>
                        </tr>
                      </thead>
                      <tbody>
                        {users.map(
                          ({
                            _id,
                            email,
                            first_name,
                            last_name,
                            color,
                            admin,
                          }) => {
                            const shared_with = file.shared_with
                              .split(',')
                              .find((x) => x === _id.toString())
                            return (
                              <tr
                                style={{ cursor: 'pointer' }}
                                onClick={() => {
                                  if (shared_with) {
                                    Swal.fire({
                                      icon: 'question',
                                      title:
                                        'Are you sure you want to stop sharing this file with ' +
                                        email +
                                        '?',
                                    }).then((response) => {
                                      if (response.dismiss) return

                                      Delete(
                                        '/file/share?f=' +
                                          file.name +
                                          '&s=' +
                                          email,
                                        {
                                          filename: file.name,
                                          shareWith: email,
                                        },
                                      )
                                        .then(({ data }) => {
                                          if (data.result === 'Success') {
                                            dispatch(fetchFiles())
                                            Swal.fire({
                                              icon: 'success',
                                              title:
                                                'Stopped sharing file successfully',
                                            })
                                          } else {
                                            Swal.fire({
                                              icon: 'error',
                                              title:
                                                'Something went wrong when stopping sharing the file',
                                              text: data.text,
                                            })
                                          }
                                        })
                                        .catch((err) => {
                                          Swal.fire({
                                            icon: 'error',
                                            title:
                                              'Something went wrong when stopping sharing the file',
                                            text: err.response.data.text,
                                          })
                                        })
                                    })
                                  } else {
                                    Swal.fire({
                                      icon: 'question',
                                      title:
                                        'Are you sure you want to share this file with ' +
                                        email +
                                        '?',
                                    }).then((response) => {
                                      if (response.dismiss) return

                                      Post('/file/share', {
                                        filename: file.name,
                                        shareWith: email,
                                      })
                                        .then(({ data }) => {
                                          if (data.result === 'Success') {
                                            dispatch(fetchFiles())
                                            Swal.fire({
                                              icon: 'success',
                                              title: 'Shared file successfully',
                                            })
                                          } else {
                                            Swal.fire({
                                              icon: 'error',
                                              title:
                                                'Something went wrong when sharing the file',
                                              text: data.text,
                                            })
                                          }
                                        })
                                        .catch((err) => {
                                          Swal.fire({
                                            icon: 'error',
                                            title:
                                              'Something went wrong when sharing the file',
                                            text: err.response.data.text,
                                          })
                                        })
                                    })
                                  }
                                }}
                              >
                                <td style={{ width: '50px' }}>
                                  <span
                                    className="round"
                                    style={{
                                      display: 'flex',
                                      justifyContent: 'center',
                                      alignItems: 'center',
                                      backgroundColor: color,
                                    }}
                                  >
                                    {capitalize(first_name.slice(0, 1))}
                                  </span>
                                </td>
                                <td className="align-middle">
                                  {first_name} {last_name}
                                </td>
                                <td className="align-middle">{email}</td>
                                <td style={{ width: '50px' }}>
                                  {shared_with ? (
                                    <span
                                      className="round"
                                      style={{
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        backgroundColor: 'rgba(0,0,0,0)',
                                        color: '#20c997',
                                      }}
                                    >
                                      <i
                                        className={`me-3 mdi mdi-checkbox-marked-circle fa-fw`}
                                        aria-hidden="true"
                                        style={{
                                          fontSize: '1.3em',
                                          marginLeft: '15px',
                                        }}
                                      ></i>
                                    </span>
                                  ) : (
                                    <span
                                      className="round"
                                      style={{
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        backgroundColor: 'rgba(0,0,0,0)',
                                        color: '#f62d51',
                                      }}
                                    >
                                      <i
                                        className={`me-3 mdi mdi-close-circle fa-fw`}
                                        aria-hidden="true"
                                        style={{
                                          fontSize: '1.3em',
                                          marginLeft: '15px',
                                        }}
                                      ></i>
                                    </span>
                                  )}
                                </td>
                              </tr>
                            )
                          },
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
export default SharePage
