const R = require('ramda')

const initialState = {
  users: [],
  username: false,
  admin: false,
  loggedIn: false,
  sid: false,
  searchText: '',
  files: [],
}

export function mainReducer(state = R.clone(initialState), action) {
  switch (action.type) {
    case 'FETCH_USERS':
      state = {
        ...state,
        users: action.payload,
      }
      return state
    case 'FETCH_FILES':
      state = {
        ...state,
        files: action.payload,
      }
      return state

    case 'SET_ADMIN':
      state = {
        ...state,
        admin: action.payload,
      }
      return state
    case 'SET_SEARCH_TEXT':
      state = {
        ...state,
        searchText: action.payload,
      }
      return state
    case 'LOGIN':
      state = {
        ...state,
        sid: action.payload.sid,
        admin: action.payload.admin === '1',
        username: action.payload.username,
        loggedIn: true,
      }
      return state
    case 'LOGOUT':
      state = {
        ...state,
        sid: false,
        admin: false,
        username: false,
        loggedIn: false,
      }
      return state
    default:
      return state
  }
}
