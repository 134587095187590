import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router'
import Swal from 'sweetalert2'
import { Delete } from '../api/actions'
import { getSid } from '../api/sid'
import url from '../api/url'
import useRedux from '../redux/hooks/useRedux'
import { fetchFiles } from '../redux/mainActions'

const ContextMenu = ({ selectedFile, close }) => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { username } = useRedux()
  return (
    <div className="card" style={{ width: '30vw', borderRadius: '10px' }}>
      <div className="card-body">
        <div className="table-responsive mt-2">
          <table className="table stylish-table no-wrap">
            <tbody>
              <tr
                style={{ cursor: 'pointer' }}
                onClick={(e) => {
                  e.preventDefault()
                  e.stopPropagation()

                  window.open(
                    `${url}/download?p=${
                      selectedFile.name
                    }&s=${encodeURIComponent(getSid())}`,
                    '_blank',
                  )
                  close()
                }}
              >
                <td style={{ width: '50px' }}>
                  <span
                    className="round"
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      backgroundColor: '#4fc3f7',
                    }}
                  >
                    <i
                      className={`mdi mdi-download`}
                      aria-hidden="true"
                      style={{ fontSize: '1.5em', paddingLeft: '0px' }}
                    />
                  </span>
                </td>
                <td className="align-middle">Download</td>
              </tr>
              <tr
                style={{ cursor: 'pointer' }}
                onClick={(e) => {
                  e.preventDefault()
                  e.stopPropagation()
                  navigate('/versions/' + selectedFile.originalName)
                  close()
                }}
              >
                <td style={{ width: '50px' }}>
                  <span
                    className="round"
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      backgroundColor: '#a34ff7',
                    }}
                  >
                    <i
                      className={`mdi mdi-animation`}
                      aria-hidden="true"
                      style={{ fontSize: '1.5em', paddingLeft: '0px' }}
                    />
                  </span>
                </td>
                <td className="align-middle">Versions</td>
              </tr>
              {selectedFile.owner === username ? (
                <tr
                  style={{ cursor: 'pointer' }}
                  onClick={(e) => {
                    e.preventDefault()
                    e.stopPropagation()
                    navigate('/share/' + selectedFile.originalName)
                  }}
                >
                  <td style={{ width: '50px' }}>
                    <span
                      className="round"
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        backgroundColor: '#55ce63',
                      }}
                    >
                      <i
                        className={`mdi mdi-share`}
                        aria-hidden="true"
                        style={{ fontSize: '1.5em', paddingLeft: '0px' }}
                      />
                    </span>
                  </td>
                  <td className="align-middle">Sharing</td>
                </tr>
              ) : null}
              {selectedFile.owner === username ? (
                <tr
                  style={{ cursor: 'pointer' }}
                  onClick={(e) => {
                    e.preventDefault()
                    e.stopPropagation()
                    Swal.fire({
                      icon: 'warning',
                      title: 'Deleting ' + selectedFile.name,
                      text: 'Are you sure you want to delete this file?',
                      showCancelButton: true,
                    }).then((response) => {
                      if (response.dismiss) {
                        return
                      } else {
                        Delete(`/file?file_name=${selectedFile.name}`)
                          .then(({ data }) => {
                            if (data.result === 'Success') {
                              dispatch(fetchFiles())
                              Swal.fire({
                                icon: 'success',
                                title: 'Delete file successfully',
                              })
                            } else {
                              Swal.fire({
                                icon: 'error',
                                title:
                                  'Something went wrong when deleting the file',
                                text: data.message,
                              })
                            }
                            close()
                          })
                          .catch((err) => {
                            Swal.fire({
                              icon: 'error',
                              title:
                                'Something went wrong when deleting the file',
                              text: err.response.data.message,
                            })
                          })
                      }
                    })
                  }}
                >
                  <td style={{ width: '50px' }}>
                    <span
                      className="round"
                      style={{
                        backgroundColor: '#f62d51',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                      }}
                    >
                      <i
                        className={`mdi mdi-delete`}
                        aria-hidden="true"
                        style={{ fontSize: '1.5em', paddingLeft: '0px' }}
                      />
                    </span>
                  </td>
                  <td className="align-middle">Delete</td>
                </tr>
              ) : null}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  )
}

export default ContextMenu
