import { Button } from '@mui/material'
import { capitalize } from 'lodash'
import { useEffect, useRef, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate, useParams } from 'react-router'
import Swal from 'sweetalert2'
import { Delete, Post } from '../api/actions'
import { getSid } from '../api/sid'
import url from '../api/url'
import useRedux from '../redux/hooks/useRedux'
import { fetchFiles, fetchUsers, fetchVersions } from '../redux/mainActions'

export const Versions = () => {
  const navigate = useNavigate()
  let { files, username } = useRedux()
  const dispatch = useDispatch()
  const { fileName } = useParams()

  const file = files.find((x) => x.name == fileName)
  const versions = files
    .filter((x) => x.original_file_id == file._id)
    .sort((a, b) => b.version_no - a.version_no)

  useEffect(() => {
    dispatch(fetchFiles())
  }, [])

  const hiddenFileInput = useRef(null)

  const latestVersion = versions[0]

  return (
    <>
      <div className="page-wrapper">
        <div className="page-breadcrumb">
          <div className="row align-items-center">
            <div className="col-md-6 col-8 align-self-center">
              <h3 className="page-title mb-0 p-0">
                Versions for file: {file['name']}
                <br />
              </h3>

              <p>Latest version: {latestVersion.version_no}</p>
              <div className="d-flex align-items-center">
                <nav aria-label="breadcrumb">
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                      <a
                        href="#"
                        onClick={() => {
                          navigate('/')
                        }}
                      >
                        Home
                      </a>
                    </li>
                    <li className="breadcrumb-item active" aria-current="page">
                      Versions
                    </li>
                  </ol>
                </nav>
              </div>
            </div>
            <div style={{ display: 'flex', justifyContent: 'right' }}>
              <Button
                variant="contained"
                onClick={(e) => {
                  hiddenFileInput.current.click()
                }}
              >
                Upload new version
              </Button>
              <input
                type="file"
                style={{ display: 'none' }}
                ref={hiddenFileInput}
                onChange={(e) => {
                  const fileUploaded = e.target.files[0]

                  const formData = new FormData()
                  formData.append('file', fileUploaded)
                  Swal.fire({
                    icon: 'info',
                    title: 'Uploading new version of file: ' + file.name,
                    text:
                      'This will be marked as version ' +
                      (parseInt(latestVersion.version_no) + 1) +
                      ', is that ok?',
                  }).then((response) => {
                    if (response.dismiss) {
                      return
                    } else {
                      fetch(
                        url +
                          '/file?v=' +
                          (parseInt(latestVersion.version_no) + 1) +
                          '&ofid=' +
                          file._id,
                        {
                          method: 'POST',
                          headers: { sid: getSid() },
                          body: formData,
                        },
                      )
                        .then((response) => response.json())
                        .then((result) => {
                          console.log('Success:', result)
                          if (result.status) {
                            dispatch(fetchFiles())
                            Swal.fire({
                              icon: 'success',
                              title: 'Successfully uploaded file',
                            })
                          } else {
                            if (!fileUploaded) return
                            Swal.fire({
                              icon: 'error',
                              title: "Couldn't upload file: " + result.message,
                            })
                          }
                        })
                        .catch((error) => {
                          console.error('Error:', error)
                          Swal.fire({
                            icon: 'error',
                            title: 'Error uploading file: ' + error,
                          })
                        })
                    }
                  })
                }}
              />
            </div>
          </div>
        </div>
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-12">
              <div className="card" style={{ marginBottom: '0px' }}>
                <div className="card-body">
                  <div className="table-responsive">
                    <table className="table stylish-table no-wrap">
                      <thead>
                        <tr>
                          <th>Name</th>
                          <th>Owner</th>
                          <th>Version No.</th>
                          <th>Comments</th>
                        </tr>
                      </thead>
                      <tbody>
                        {versions.map((version) => (
                          <tr
                            style={{ cursor: 'pointer' }}
                            onClick={() => {
                              // const v =
                              //   version.version_no === '1'
                              //     ? ''
                              //     : `_v${version.version_no}`
                              window.open(
                                `${url}/download?p=${
                                  version.version_path
                                }&s=${encodeURIComponent(getSid())}`,
                                '_blank',
                              )
                            }}
                          >
                            <td className="align-middle">{version.name}</td>
                            <td className="align-middle">{version.owner}</td>
                            <td className="align-middle">
                              {version.version_no}
                            </td>
                            <td className="align-middle">{version.comments}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
export default Versions
