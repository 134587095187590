import dayjs from 'dayjs'
import { capitalize } from 'lodash'
import useRedux from '../redux/hooks/useRedux'

const FilesTable = ({ data, showModal }) => {
  const { username, users } = useRedux()
  if (!data) return null
  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-sm-12">
          <div className="card">
            <div className="card-body">
              <h4 className="card-title">My Files</h4>
              <div className="table-responsive">
                <table className="table user-table no-wrap">
                  <thead>
                    <tr>
                      <th className="border-top-0">Name</th>
                      <th className="border-top-0">Uploaded Date</th>
                      <th className="border-top-0">Owner</th>
                      <th className="border-top-0">Uploaded by</th>
                      <th className="border-top-0">Version No.</th>
                    </tr>
                  </thead>
                  <tbody>
                    {data.map((d, i) => (
                      <tr>
                        <td>{d.name}</td>
                        <td>
                          {dayjs.unix(d.timestamp).format('HH:mm DD-MM-YY')}
                        </td>
                        <td>{d.owner === username ? 'Me' : d.owner}</td>

                        <td>
                          <div className="text-start upgrade-btn">
                            {d.uploaded_by}
                          </div>
                        </td>
                        <td>
                          <div className="text-start upgrade-btn">
                            {d.version_no}
                          </div>
                        </td>
                        <td>
                          <div className="text-end upgrade-btn">
                            <a
                              className="d-none d-md-inline-block text-blue context-button"
                              style={{ cursor: 'pointer' }}
                              onClick={(e) => {
                                e.preventDefault()
                                e.stopPropagation()
                                showModal({
                                  name:
                                    d.version_no === '1'
                                      ? d.name
                                      : d.version_path.split('/').pop(),
                                  originalName: d.name,
                                  owner: d.owner,
                                })
                              }}
                            >
                              <i
                                className=" mdi mdi-apps fa-fw"
                                aria-hidden="true"
                                i
                              />
                            </a>
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default FilesTable
