import Axios from 'axios'
import { getSid } from './sid'
import url from './url'

export const Login = (data) =>
  new Promise((resolve, reject) => {
    Axios.post(`${url}/login`, data)
      .then((response) => {
        resolve(response)
      })
      .catch((err) => {
        console.log(err)
        reject(err)
      })
  })

export const Get = (u, headers = {}) =>
  new Promise((resolve, reject) => {
    let s = getSid()
    let h = { ...headers, sid: s }
    Axios.get(`${url}${u}`, {
      headers: h,
    })
      .then((response) => {
        resolve(response)
      })
      .catch((err) => {
        console.log(err)
        reject(err)
      })
  })

export const Post = (u, data, headers = {}) =>
  new Promise((resolve, reject) => {
    let s = getSid()
    let h = { ...headers, sid: s }
    Axios.post(`${url}${u}`, data, { headers: h })
      .then((response) => {
        resolve(response)
      })
      .catch((err) => {
        console.log(err)
        reject(err)
      })
  })

export const Put = (u, data, headers = {}) =>
  new Promise((resolve, reject) => {
    let s = getSid()
    let h = { ...headers, sid: s }
    Axios.put(`${url}${u}`, data, { headers: h })
      .then((response) => {
        resolve(response)
      })
      .catch((err) => {
        console.log(err)
        reject(err)
      })
  })

export const Delete = (u, headers = {}) =>
  new Promise((resolve, reject) => {
    let s = getSid()
    let h = { ...headers, sid: s }
    Axios.delete(`${url}${u}`, { headers: h })
      .then((response) => {
        resolve(response)
      })
      .catch((err) => {
        console.log(err)
        reject(err)
      })
  })
