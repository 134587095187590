import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router'
import useRedux from '../redux/hooks/useRedux'
import { logout } from '../redux/mainActions'

const routes = [
  { label: 'My Files', iconClass: 'file', link: '/' },
  // { label: 'Profile', iconClass: 'face-profile', link: '/profile' },
  {
    label: 'Admin',
    iconClass: 'account-settings-variant',
    link: '/admin',
    admin: true,
  },
]
export const SideBar = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { admin } = useRedux()
  return (
    <aside className="left-sidebar" data-sidebarbg="skin6">
      <div className="scroll-sidebar">
        <nav className="sidebar-nav">
          <ul id="sidebarnav">
            {routes.map((route) => {
              if (route.admin) {
                if (!admin) return null
              }
              return (
                <li className="sidebar-item" key={route.label}>
                  <a
                    onClick={(e) => {
                      e.preventDefault()
                      e.stopPropagation()
                      navigate(route.link)
                    }}
                    className="sidebar-link waves-effect waves-dark sidebar-link"
                    href="index.html"
                    aria-expanded="false"
                  >
                    {/* <i className="me-3 far fa-clock fa-fw" aria-hidden="true"></i> */}
                    <i
                      className={`me-3 mdi mdi-${route.iconClass} fa-fw`}
                      aria-hidden="true"
                      style={{ fontSize: '1.9em' }}
                    ></i>
                    <span className="hide-menu">{route.label}</span>
                  </a>
                </li>
              )
            })}
            <li className="sidebar-item">
              <a
                onClick={(e) => {
                  e.preventDefault()
                  e.stopPropagation()
                  navigate('/')
                  dispatch(logout())
                }}
                className="sidebar-link waves-effect waves-dark sidebar-link"
                aria-expanded="false"
              >
                <i
                  className={`me-3 mdi mdi-logout fa-fw`}
                  aria-hidden="true"
                  style={{ fontSize: '1.9em' }}
                ></i>
                <span className="hide-menu">Logout</span>
              </a>
            </li>
          </ul>
        </nav>
      </div>
    </aside>
  )
}
