import { useNavigate } from 'react-router'
import url from '../api/url'

export const Admin = () => {
  const navigate = useNavigate()
  return (
    <div className="page-wrapper">
      <div className="page-breadcrumb">
        <div className="row align-items-center">
          <div className="col-md-6 col-8 align-self-center">
            <h3 className="page-title mb-0 p-0">Admin</h3>
            <div className="d-flex align-items-center">
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <a
                      href="#"
                      onClick={() => {
                        navigate('/')
                      }}
                    >
                      Home
                    </a>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    Admin
                  </li>
                </ol>
              </nav>
            </div>
          </div>
        </div>
      </div>
      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-12">
            <div className="card" style={{ marginBottom: '0px' }}>
              <div className="card-body">
                <div className="table-responsive">
                  <table className="table stylish-table no-wrap">
                    <tbody>
                      <tr
                        style={{ cursor: 'pointer' }}
                        onClick={() => {
                          navigate('/users')
                        }}
                      >
                        <td style={{ width: '50px' }}>
                          <span
                            className="round"
                            style={{
                              display: 'flex',
                              justifyContent: 'center',
                              alignItems: 'center',
                              backgroundColor: '#4fc3f7',
                            }}
                          >
                            <i
                              className={`mdi mdi-account-multiple`}
                              aria-hidden="true"
                              style={{
                                fontSize: '1.5em',
                                paddingLeft: '0px',
                              }}
                            />
                          </span>
                        </td>
                        <td className="align-middle">User Management</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
export default Admin
