import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { loginChain } from '../redux/mainActions'
import Cookies from 'js-cookie'
import { Get, Post } from '../api/actions'
import { setSid } from '../api/sid'
import Swal from 'sweetalert2'

const Login = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [data, setData] = useState({})

  useEffect(() => {
    try {
      const obj = JSON.parse(Cookies.get('sid'))
      const { sid } = obj
      if (sid) {
        setSid(sid)
        // check the sid works
        Get(`/users-simple`, { sid })
          .then(({ data }) => {
            dispatch({
              type: 'LOGIN',
              payload: obj,
            })
          })
          .catch((err) => {
            // cookie is expired - delete it
            Cookies.remove('sid')
          })
      } else {
        Cookies.remove('sid')
      }
    } catch (err) {
      // cookie is expired - delete it
      Cookies.remove('sid')
    }
  }, [])

  const askChangePass = () => {
    Swal.fire({
      icon: 'question',
      input: 'password',
      title: 'Change your password',
      inputPlaceholder: 'Please enter a new password',
    }).then((response) => {
      if (response.dismiss || !response.value) {
        askChangePass()
      } else {
        Swal.fire({
          icon: 'warning',
          input: 'password',
          title: 'Confirm new password',
          inputPlaceholder: 'Please enter the same password again',
        }).then((response2) => {
          if (response.value !== response2.value) {
            Swal.fire({
              title: "Passwords don't match, please try again",
            }).then(() => {
              askChangePass()
            })
          } else {
            Post('/changepass', {
              newPassword: response.value,
            })
              .then(({ data }) => {
                if (data.result === 'Success') {
                  Swal.fire({ icon: 'success', title: 'Changed password' })
                  navigate('/')
                }
              })
              .catch((err) => {
                Swal.fire({
                  icon: 'error',
                  title: 'Failed to change password',
                })
              })
          }
        })
      }
    })
  }

  const login = (e) => {
    e.preventDefault()
    dispatch(loginChain(data))
      .then((r) => {
        if (!r.changedPassword) {
          askChangePass()
        } else {
          navigate('/')
        }
      })
      .catch((err) => {
        Swal.fire({ icon: 'error', title: 'Email or password is wrong' })
      })
  }
  return (
    <div
      className="page-wrapper"
      style={{ marginLeft: '0', backgroundColor: 'white' }}
    >
      <div className="container-fluid">
        <div
          className="row"
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '90vh',
          }}
        >
          <div className="col-lg-3" />
          <div className="col-lg-6">
            <div className="card">
              <div className="card-body">
                <form
                  className="form-horizontal form-material mx-2"
                  onSubmit={login}
                >
                  <div className="form-group">
                    {/* <label className="col-md-12 mb-0">Email Address:</label> */}
                    <div className="col-md-12">
                      <input
                        type="text"
                        placeholder="Email address"
                        className="form-control ps-0 form-control-line"
                        onChange={(e) => {
                          setData({ ...data, email: e.target.value })
                        }}
                        value={data.email}
                      />
                    </div>
                  </div>
                  <div className="form-group">
                    {/* <label className="col-md-12 mb-0">Password:</label> */}
                    <div className="col-md-12">
                      <input
                        type="password"
                        placeholder="Password"
                        className="form-control ps-0 form-control-line"
                        onChange={(e) => {
                          setData({ ...data, password: e.target.value })
                        }}
                        value={data.password}
                      />
                    </div>
                  </div>
                  <div className="form-group">
                    <div className="col-sm-12 d-flex">
                      <button className="btn btn-success mx-auto mx-md-0 text-white">
                        Login
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
          <div className="col-lg-3" />
        </div>
      </div>
    </div>
  )
}

export default Login
