import { Get, Login, Post } from '../api/actions'
import { set } from 'js-cookie'
import Cookies from 'js-cookie'
import { setSid } from '../api/sid'

export const fetchUsers = () => (dispatch) => {
  Get('/users-simple').then(({ data }) => {
    dispatch({
      type: 'FETCH_USERS',
      payload: data,
    })
  })
}

export const fetchUsersFull = () => (dispatch) => {
  Get('/users').then(({ data }) => {
    dispatch({
      type: 'FETCH_USERS',
      payload: data,
    })
  })
}

export const fetchFiles = () => (dispatch) => {
  Get('/files').then(({ data }) => {
    dispatch({
      type: 'FETCH_FILES',
      payload: data,
    })
  })
}

export const loginChain = (data) => (dispatch) =>
  new Promise((resolve, reject) => {
    Login(data)
      .then((response) => {
        let obj = {
          sid: response.data.sid,
          username: data.email,
          admin: response.data.admin,
        }

        setSid(response.data.sid)
        dispatch({
          type: 'LOGIN',
          payload: obj,
        })
        // set the cookie
        Cookies.set('sid', JSON.stringify(obj))
        resolve(response.data)
      })
      .catch((err) => {
        reject(err)
      })
  })

export const logout = () => {
  Cookies.remove('sid')
  setSid('')
  return { type: 'LOGOUT' }
}

export const setAdmin = (data) => ({ type: 'SET_ADMIN', payload: data })

export const setSearchText = (data) => ({
  type: 'SET_SEARCH_TEXT',
  payload: data,
})
